import { sortImagesByOrder } from 'src/common/constants/common.utils';
import { EGiftType, ETransportType, EVCard, PreOrderStatus, TierCode } from './interface';

export const listTypeBanner: { id: EGiftType; name: EGiftType }[] = [
  { id: EGiftType.EV_VITA_CODE, name: EGiftType.EV_VITA_CODE },
  { id: EGiftType.E_VOUCHER, name: EGiftType.E_VOUCHER },
  { id: EGiftType.E_VOUCHER_SHOP, name: EGiftType.E_VOUCHER_SHOP },
];

export const MapGift = (gift: any) => {
  return gift?.id
    ? {
        ...gift,
        categoryId: gift.categoryCode,
        rewardAppName: gift.name,
        description: gift?.dynamicData?.description || '',
        tagId: gift.badgeCodes?.[0],
        image: gift.images.filter((i: any) => i)?.[0],
        covers: sortImagesByOrder(gift.images.filter((i: any) => i).slice(1)),
        imageFile: gift.images.filter((i: any) => i)[0],
        coverFiles: sortImagesByOrder(gift.images.filter((i: any) => i).slice(1)),
        giftType: gift.type,
        tierCodes: gift.tierCodes,
        rewardAppGiftCode: gift?.dynamicData?.rewardAppGiftCode || '',
        priority: gift.priority?.toString(),
        point: gift.point?.toString(),
        money: gift.price?.toString(),
        smsGiftId: gift?.dynamicData?.smsGiftId || '',
        activeSmsNotification: gift?.dynamicData?.activeSmsNotification || false,
        startDate: gift.startDate,
        endDate: gift.endDate,
        expiredDate: gift.expireDate,
        expiredTime: gift.expireHour,
        codeSCT: gift.sctNumber,
        total: gift.quantity,
        totalBucket: gift.inventory,
        sourceGift: gift?.dynamicData?.sourceGift,
        limitGiftCount: gift.quantityLimitForBooking,
        height: gift?.dynamicData?.height,
        width: gift?.dynamicData?.width,
        length: gift?.dynamicData?.length,
        weight: gift?.dynamicData?.weight,
        transportType: gift.transportTypeCode,
        status: gift.status === GIFT_STATUS.ENABLED,
        allowReservationInCoin: gift?.giftReservation?.reservationPercent,
        maximumReservationQuantity: gift?.giftReservation?.maximumReservationQuantity,
        reservationPeriodDays: gift?.giftReservation?.reservationExpiredDays,
        limitReservationTime: gift?.giftReservation?.limitReservationTime,
        discountReservation: gift?.giftReservation?.discountReservationPercent,
        coinToMinus: gift?.giftReservation?.coinToMinusPercent,
        dynamicFilter: gift.dynamicData.dynamicFilter,
      }
    : DEFAULT_VALUE_GIFT_FORM;
};

export const DEFAULT_VALUE_GIFT_FORM = {
  rewardAppName: undefined,
  description: undefined,
  tagId: undefined,
  image: undefined,
  covers: [],
  imageFile: null,
  coverFiles: [],
  giftType: undefined,
  tierCodes: [],
  rewardAppGiftCode: undefined,
  money: undefined,
  point: undefined,
  priority: undefined,
  smsGiftId: undefined,
  activeSmsNotification: false,
  endDate: null,
  startDate: null,
  expiredDate: null,
  expiredTime: undefined,
  codeSCT: undefined,
  total: undefined,
  totalBucket: undefined,
  sourceGift: undefined,
  limitGiftCount: undefined,
  height: undefined,
  width: undefined,
  length: undefined,
  weight: undefined,
  transportType: undefined,
  status: false,
  categoryId: undefined,
  isAllowReservation: false,
  allowReservationInCoin: undefined,
  maximumReservationQuantity: undefined,
  reservationPeriodDays: undefined,
  limitReservationTime: undefined,
  discountReservation: undefined,
  coinToMinus: undefined,
  dynamicFilter: undefined,
};

export enum STATUS {
  ACTIVE = 'ACTIVE',
  IN_ACTIVE = 'IN_ACTIVE',
}
export enum GIFT_STATUS {
  ENABLED = 'ENABLED',
  DISABLED = 'DISABLED',
}

export const SEARCH_BY = {
  EVENT_NAME: 'EVENT_NAME',
  EVENT_GROUP_NAME: 'EVENT_GROUP_NAME',
};

export const TRANSPORT_TYPE = [
  { label: 'First Special', value: ETransportType.FIRST_SPECIAL },
  { label: 'Second Special', value: ETransportType.SECOND_SPECIAL },
  { label: 'Sample', value: ETransportType.SAMPLE },
  { label: 'Exchange', value: ETransportType.EXCHANGE },
];

export const TIER_CODE_LIST = [
  { label: 'Member', value: TierCode.MEMBER },
  { label: 'Titan', value: TierCode.TITAN },
  { label: 'Gold', value: TierCode.GOLD },
  { label: 'Platinum', value: TierCode.PLATINUM },
  { label: 'Silver', value: TierCode.SILVER },
];

export const PRE_ORDER_STATUS_LIST = [
  { label: 'Pre-Order', value: PreOrderStatus.PRE_ORDER },
  { label: 'Exchanged', value: PreOrderStatus.EXCHANGED },
  { label: 'Cancel', value: PreOrderStatus.CANCEL },
];

export const GIFT_TYPE = [
  { label: 'Gift', value: EGiftType.GIFT },
  { label: 'E-Voucher', value: EGiftType.E_VOUCHER },
  { label: 'E-Voucher Shop Beekids', value: EGiftType.E_VOUCHER_SHOP_BEEKIDS },
  { label: 'E-Voucher Shop', value: EGiftType.E_VOUCHER_SHOP },
  { label: 'EV Vita Code', value: EGiftType.EV_VITA_CODE },
];

export const SOURCE_GIFT = [
  { label: 'Vitadairy Products', value: 'Vitadairy Products' },
  { label: 'Gimmick', value: 'Gimmick' },
];

export const TABLE_HEAD_GIFT_LIST = [
  { id: 'id', label: 'ID', align: 'center' },
  { id: 'images', label: 'Image', align: 'center' },
  {
    id: 'name',
    label: 'Name',
    align: 'left',
  },
  {
    id: 'type',
    label: 'Type',
    align: 'left',
  },
  {
    id: 'sct',
    label: 'SCT',
    align: 'left',
  },
  {
    id: 'total',
    label: 'Total',
    align: 'left',
  },
  {
    id: 'actualPoint',
    label: 'Display Point',
    align: 'left',
  },
  {
    id: 'money',
    label: 'Money',
    align: 'left',
  },
  {
    id: 'active',
    label: 'Active',
    align: 'left',
  },
];

export const TABLE_HEAD_GIFT_CATEGORY_LIST = [
  {
    id: 'priority',
    label: 'Priority',
    align: 'left',
  },
  {
    id: 'categoryName',
    label: 'Category Name',
    align: 'left',
  },
  {
    id: 'categoryCode',
    label: 'Category Code',
    align: 'left',
  },

  {
    id: 'active',
    label: 'Active',
    align: 'left',
  },
];

export const TABLE_HEAD_HIDDEN_TAG_LIST = [
  { id: 'stt', label: 'STT', align: 'center' },
  {
    id: 'name',
    label: 'Tag Name',
    align: 'left',
  },
  {
    id: 'active',
    label: 'Active',
    align: 'left',
  },
];

export const TABLE_HEAD_GIFT_PRE_ORDER_LIST = [
  {
    id: 'id',
    label: 'ID',
    align: 'left',
  },
  {
    id: 'createdAt',
    label: 'Ngày tạo',
    align: 'left',
  },
  {
    id: 'customer',
    label: 'Khách hàng',
    align: 'left',
  },
  {
    id: 'reversePoint',
    label: 'Xu đặt trước',
    align: 'left',
  },
  {
    id: 'gift',
    label: 'Tên quà',
    align: 'left',
  },
  {
    id: 'status',
    label: 'Trạng thái',
    align: 'left',
  },
  {
    id: 'completedAt',
    label: 'Ngày hoàn tất',
    align: 'left',
  },
  {
    id: 'rejectedAt',
    label: 'Ngày hủy',
    align: 'left',
  },
  {
    id: 'expiredAt',
    label: 'Ngày hết hạn',
    align: 'left',
  },
];

export const DEFAULT_VALUE_GIFT_CATEGORY_FORM = {
  name: undefined,
  priority: undefined,
  isActive: false,
  code: undefined,
};

export const DEFAULT_VALUE_GIFT_EV_VITE_CODE = {
  priceId: 1,
  eproductId: 1,
  eproductName: 'Mã E-Voucher (VNVC)',
};

export const GIFT_EV_CARD: EVCard[] = [
  {
    id: 1,
    point: 2,
    amount: 10000,
    eproductName: 'Quà tặng Nạp Tiền Điện Thoại',
    eproductId: 987,
    priceId: 2165,
  },
  {
    id: 2,
    point: 4,
    amount: 20000,
    eproductName: 'Quà tặng Nạp Tiền Điện Thoại - Sữa Vitadairy Việt Nam',
    eproductId: 3644,
    priceId: 7239,
  },
  {
    id: 3,
    point: 10,
    amount: 50000,
    eproductName: 'Quà tặng Nạp Tiền Điện Thoại - Sữa Vitadairy Việt Nam',
    eproductId: 3644,
    priceId: 7240,
  },
  {
    id: 4,
    point: 20,
    amount: 100000,
    eproductName: 'Quà tặng Nạp Tiền Điện Thoại - Sữa Vitadairy Việt Nam',
    eproductId: 3644,
    priceId: 7241,
  },
];
